import * as React from "react";
import { graphql } from "gatsby";
import {
  MediaPhoto,
  MediaVideo
} from "../components/common/MediaGallery/classes/Media";
import { YouTubeFields } from "./fragments/NodeYouTubeFields";
import { FlickrFields } from "./fragments/NodeFlickrFields";
import PageHero from "../components/common/PageHero";

import ReactPlayer from "react-player";
import MediaImage from "../components/common/MediaGallery/parts/MediaImage";
import { Container } from "react-bootstrap";
import { Typography } from "../components/ui/Typography";
import { Breadcrumbs } from "../components/common/MediaGallery";
import MediaTags from "../components/common/MediaGallery/parts/MediaTags";
import { WmkSeo } from "wmk-seo";

const MediaNode = ({
  data,
  pageContext
}: {
  data: SingleMediaQuery;
  pageContext: { title: string; id: string; slug: string; pagePath: string };
}) => {
  const { video, photo } = data;
  const media = video ? new MediaVideo(video) : new MediaPhoto(photo);
  return (
    <>
      <WmkSeo.Meta
        title={pageContext.title}
        description={media.description}
        path={pageContext.pagePath}
        slug={pageContext.slug}
        siteTitle={"UTRWD"}
        baseUrl="https://www.utrwd.com"
      />
      <PageHero title={media.type} />
      <Container style={{ padding: `0 0 2rem 0` }}>
        <Breadcrumbs type={media.type} currMedia={media} />
        <Typography.H4>{media.title}</Typography.H4>
        <MediaTags tags={media.tags} type={media.type} />
        {media.type === "photos" ? (
          <MediaImage image={media.url} alt={media.title} />
        ) : (
          <ReactPlayer url={`https://youtube.com/watch?v=${media.uid}`} />
        )}
      </Container>
    </>
  );
};

export default MediaNode;

interface SingleMediaQuery {
  video: YouTubeFields | null;
  photo: FlickrFields | null;
}

export const query = graphql`
  query mediaQuery($id: String!) {
    video: youtubeVideo(id: { eq: $id }) {
      ...NodeYoutubeFields
    }
    photo: flickrPhoto(id: { eq: $id }) {
      ...NodeFlickrFields
    }
  }
`;
