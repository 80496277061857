import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { Tag } from "wmk-tag";
import { colors } from "../../../ui/colors";
import { GalleryQuery } from "../MediaGalleryPage";
import { basePath } from "./Breadcrumbs";
import { startCase } from "lodash";
import { slugify } from "../../../routes/Home/NewsAndEvents/NewsFeed/PostTag";
import { mediaReferences } from "../MediaRepeaters";

const StyledTags = styled(Col)`
  font-size: 1rem;
  a {
    color: ${colors.blue.toString()};
    &:hover {
      color: ${colors.orange.toString()};
    }
  }
`;

const MediaTags = ({ tags, type }: { tags: Tag[]; type: string }) => {
  // const data: GalleryQuery = useStaticQuery(query);
  const data = {
    catData: {
      blocks: [
        {
          copy: {
            references: mediaReferences
          }
        }
      ]
    }
  };
  tags = tags.reduce((matches, tag) => {
    data.catData.blocks.forEach((block) => {
      block.copy.references.forEach((repeater) => {
        const regex = new RegExp(type.slice(0, type.length - 1), "i");
        if (Array.isArray(repeater.title.match(regex))) {
          repeater.categories.forEach((t) => {
            if (slugify(startCase(t.slug)) === tag.slug) {
              matches.push(tag);
            }
          });
        }
      });
    });
    return matches;
  }, []);
  return Array.isArray(tags) && tags.length > 0 ? (
    <Container style={{ padding: `0 0 .75rem 0` }}>
      <Row>
        <StyledTags xs="auto">Tags: </StyledTags>
        {tags.map((t, i) => {
          return (
            <StyledTags key={t.slug + i} xs="auto">
              <WmkLink to={`${basePath}/${type}/${t.slug}`}>{t.name}</WmkLink>
            </StyledTags>
          );
        })}
      </Row>
    </Container>
  ) : null;
};

export default MediaTags;

// const query = graphql`
//   {
//     catData: contentfulPages(slug: { eq: "media" }) {
//       blocks: mainBuilderBlocks {
//         copy {
//           references {
//             ... on ContentfulRepeater {
//               title
//               categories: data {
//                 name: key
//                 slug: value
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;
