

export const categories = [
  { name: "Teacher/Student Resources", slug: "#TeacherStudentResources" },
  { name: "Watershed Protection", slug: "#WatershedProtection" },
  { name: "How to Save Water", slug: "#HowToSaveWater" },
  { name: "Irrigation System Tips", slug: "#IrrigationSystemTips" },
  { name: "Virtual Classes", slug: "#VirtualClasses" }
];

export const mediaReferences = [
  { categories: [], title: "UTRWD Photo Categories" },
  { categories: categories, title: "UTRWD Video Categories" }
];