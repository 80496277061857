import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { GatsbyImageData } from "wmk-image";

const MediaImage = ({
  image,
  alt
}: {
  image: string | GatsbyImageData;
  alt: string;
}) =>
  typeof image === "string" ? (
    <img src={image} alt={alt} style={{ maxWidth: `100%` }} />
  ) : (
    <GatsbyImage image={image} alt={alt} />
  );

export default MediaImage;
